import TopBarLayout from "../components/Layout/TopBarLayout";
import MainLayout from "../components/Layout/MainLayout";
import MaterialTextField from "../components/MaterialTextField";
import {useRef, useState} from "react";
import {css} from "@emotion/react";
import colors from "../styles/Colors";
import MaterialButton from "../components/MaterialButton";
import {updateUserPassword} from "../lib/api/user";
import LoadingDialog from "../components/Dialog/LoadingDialog";
import crypto from "crypto";
import TipxyDialog from "../components/Dialog/TipxyDialog";

const textFieldContainerStyle = css`
  text-align: center;
  margin-bottom: 12px;

  & > * {
    margin-bottom: 4px;
  }
`;

const warningTextStyle = css`
  color: ${colors.secondary};
`;

const ChangePasswordPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isComplete, setIsComplete] = useState(false);

    const [currentPasswordError, setCurrentPasswordError] = useState('');
    const [newPasswordError, setNewPasswordError] = useState('');
    const [newPasswordConfirmError, setNewPasswordConfirmError] = useState('');
    const [validation, setValidation] = useState(false);

    const currentPasswordRef = useRef();
    const newPasswordRef = useRef();
    const newPasswordConfirmRef = useRef();

    const checkValidation = () => {
        setValidation(false);
        setCurrentPasswordError('');
        setNewPasswordError('');
        setNewPasswordConfirmError('');

        const newPassword = newPasswordRef.current.value;
        const newPasswordConfirm = newPasswordConfirmRef.current.value;

        const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d~!@#$%^&*_\-+=`|\\(){}\[\]:;"'<>,.?/]{8,}$/;
        const validation = regex.test(newPassword);

        if (!validation) {
            setNewPasswordError('비밀번호는 8자 이상, 영문, 숫자가 포함되어야 합니다.');
            return;
        }

        if (newPassword !== newPasswordConfirm) {
            setNewPasswordConfirmError('비밀번호가 일치하지 않습니다.');
            return;
        }

        setValidation(true);
    }

    const updatePassword = async () => {
        setIsLoading(true);

        const currentPassword = currentPasswordRef.current.value;
        const newPassword = newPasswordRef.current.value;

        await updateUserPassword({
            current_password: encryptPassword(currentPassword),
            new_password: encryptPassword(newPassword),
        }).then((res) => {
            currentPasswordRef.current.value = '';
            newPasswordRef.current.value = '';
            newPasswordConfirmRef.current.value = '';

            setCurrentPasswordError('');
            setNewPasswordError('');
            setNewPasswordConfirmError('');

            setValidation(false);
            setIsComplete(true);
        })
            .catch(({response}) => setCurrentPasswordError(response.data.message))
            .finally(() => setIsLoading(false));
    }

    const encryptPassword = (password) => crypto.createHash('sha256').update(password).digest('base64');

    return (
        <TopBarLayout>
            <MainLayout>
                <div css={textFieldContainerStyle}>
                    <MaterialTextField ref={currentPasswordRef} type="password" placeholder={'현재 비밀번호'}
                                       onChange={checkValidation} fullWidth/>
                    <span css={warningTextStyle}>{currentPasswordError}</span>
                    <MaterialTextField ref={newPasswordRef} type="password" placeholder={'새로운 비밀번호'}
                                       onChange={checkValidation} fullWidth/>
                    <span css={warningTextStyle}>{newPasswordError}</span>
                    <MaterialTextField ref={newPasswordConfirmRef} type="password" placeholder={'새로운 비밀번호 확인'}
                                       onChange={checkValidation} fullWidth/>
                    <span css={warningTextStyle}>{newPasswordConfirmError}</span>
                </div>
                <MaterialButton onClick={updatePassword} disabled={!validation}>비밀번호 변경</MaterialButton>

                <TipxyDialog open={isComplete} onSubmitHandler={() => setIsComplete(false)}>
                    비밀번호 변경에 성공했습니다.
                </TipxyDialog>
                <LoadingDialog open={isLoading}/>
            </MainLayout>
        </TopBarLayout>
    );
}

export default ChangePasswordPage;