import React, {useRef} from 'react';
import MainLayout from "../components/Layout/MainLayout";
import MaterialTextField from "../components/MaterialTextField";
import TopBarLayout from "../components/Layout/TopBarLayout";
import CardView from "../components/CardView";
import MaterialSelect from "../components/MaterialSelect";
import {css, MenuItem} from "@mui/material";
import MaterialButton from "../components/MaterialButton";
import CircleLineIcon from "../icons/CircleLineIcon";
import colors from "../styles/Colors";
import GlobeIcon from "../icons/GlobeIcon";
import LocationIcon from "../icons/LocationIcon";
import PhoneIcon from "../icons/PhoneIcon";
import InstagramIcon from "../icons/InstagramIcon";
import ClockIcon from "../icons/ClockIcon";
import TagIcon from "../icons/TagIcon";
import RestaurantIcon from "../icons/RestaurantIcon";
import CameraIcon from "../icons/CameraIcon";
import ChevronDownIcon from "../icons/ChevronDownIcon";
import {useEffect, useState} from "react";
import {updateBar} from "../lib/api/bar";
import IntroduceContainer from "../components/IntroduceContainer";
import LoadingDialog from "../components/Dialog/LoadingDialog";
import OpeningHourDialog from "../components/Dialog/OpeningHourDialog";
import TagDialog from "../components/Dialog/TagDialog";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import MenuDialog from "../components/Dialog/MenuDialog";
import {BarActions} from "../store/barStore";
import {uploadImage} from "../lib/api/image";
import TipxyDialog from "../components/Dialog/TipxyDialog";
import PencilIcon from "../icons/PencilIcon";

const cardStyle = css`
  margin-bottom: 15px;

  & > div {
    margin-bottom: 15px;
  }
`;

const containerStyle = css`
  display: flex;
  color: ${colors.font.dim300};
  flex-direction: column;
  background-color: ${colors.background.light};
  border-radius: 20px;
`;

const addPhotoStyle = (filename) => css`
  ${containerStyle};
  height: 100px;
  background: url(${filename}) center center/cover;
  background-color: ${colors.background.light};
  text-align: center;
  font-size: 12px;

  & > svg {
    margin: 20px auto 10px;
  }
`;

const openingHourContainerStyle = css`
  ${containerStyle};
  padding: 20px;
`;

const openingHourStyle = css`
  margin: 4px 0;
  color: ${colors.font.default};
`;

const noOpeningHourStyle = css`
  margin: auto;
`;

const ShopPage = () => {
    const bars = useSelector(state => state.bar);
    const locations = useSelector(state => state.location);

    const {index = 0} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const bar = bars[index];

    const [barLocation, setBarLocation] = useState(bar?.location);

    const [openOpeningHourDialog, setOpenOpeningHourDialog] = useState(false);
    const [openTagDialog, setOpenTagDialog] = useState(false);
    const [openMenuDialog, setOpenMenuDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [shopImage, setShopImage] = useState();

    const fileInputRef = useRef();

    const nameRef = useRef();
    const addressRef = useRef();
    const contactRef = useRef();
    const instagramRef = useRef();
    const introduceCommentRef = useRef();
    const introduceOwnerRef = useRef();

    const onSubmitHandler = () => {
        const request = {
            ...bar,
            bar_id: bar?.id,
            name: nameRef.current.value,
            address: addressRef.current.value,
            location: barLocation ?? bar?.location,
            contact: contactRef.current.value,
            instagram: instagramRef.current.value,
            introduce_owner: introduceOwnerRef.current.value,
            introduce_comment: introduceCommentRef.current.value,
        }

        setIsLoading(true);
        if (shopImage)
            uploadImage(shopImage.file, 1).then((res) => {
                request.image_id = res;
                updateBarHandler(request);
            }).catch(e => setErrorMessage(e.response.data.message))
                .finally(() => setIsLoading(false));
        else updateBarHandler(request);
    }

    const updateBarHandler = (request) => {
        updateBar(request).then((res) => {
            dispatch(BarActions.setBar({
                index: index,
                bar: res
            }))
        }).catch(e => setErrorMessage(e.response.data.message))
            .finally(() => setIsLoading(false));
    }

    const onFileUpdated = ({target}) => {
        setShopImage({
            id: bar?.id,
            file: target.files[0]
        });
    }

    const getFileUrl = () => {
        if (shopImage && shopImage?.id === bar?.id) return URL.createObjectURL(shopImage?.file);
        if (bar?.image_id) return process.env.REACT_APP_TIPXY_S3_URL + bar?.filename;
        else return undefined;
    }

    const openInstagramPage = () => {
        if (bar?.instagram_access_token) {
            const isProduction = location.host === 'owner.tipxy-now.com';
            return window.open(`https://${isProduction ? 'tipxy-now.com' : 'develop.tipxy-now.com'}/shop/${bar?.instagram}`);
        }

        window.open(`${process.env.REACT_APP_TIPXY_API_URL}/api/public/instagram`);
        window.addEventListener('focusin', (event) => {
            navigate(0);
        });
    }

    useEffect(() => {
        setShopImage(undefined);
    }, [bar?.id]);

    return (
        <TopBarLayout key={bar}>
            <MainLayout key={index}>
                <CardView css={cardStyle} title='기본 정보'>
                    <div css={addPhotoStyle(getFileUrl())} onClick={() => fileInputRef.current.click()}>
                        <input ref={fileInputRef} type='file' accept='image/*' onChange={onFileUpdated} hidden/>
                        {!(bar?.filename || shopImage) && (
                            <>
                                <CameraIcon/>
                                <span>이 곳을 탭하여 업소 사진을 등록해주세요.</span>
                                <span>업소 간판이 중앙에 위치하는 사진을 권장합니다.</span>
                            </>
                        )}
                    </div>
                    <MaterialTextField ref={introduceCommentRef} icon={<PencilIcon size={1.2}/>}
                                       placeholder='한 줄 소개를 입력해주세요.' defaultValue={bar?.introduceComment}/>
                    <MaterialTextField ref={nameRef} icon={<CircleLineIcon/>} placeholder='업소 이름을 입력해주세요.'
                                       defaultValue={bar?.name}/>
                    <MaterialSelect icon={<GlobeIcon/>} arrowIcon={<ChevronDownIcon/>} defaultValue={bar?.location}
                                    onChange={({target}) => setBarLocation(target.value)}>
                        {locations.map((e) => (
                            <MenuItem key={e.id} value={e.id} style={{color: colors.font.default}}>{e.name}</MenuItem>
                        ))}
                    </MaterialSelect>
                    <MaterialTextField ref={addressRef} icon={<LocationIcon/>} placeholder='업소의 주소를 입력해주세요.'
                                       defaultValue={bar?.address}/>
                </CardView>

                <CardView css={cardStyle} title='연락처' subtitle='예약 및 문의를 위한 연락처를 반드시 하나 이상 입력바랍니다.'>
                    <MaterialTextField ref={contactRef} icon={<PhoneIcon/>} placeholder='업소의 전화번호를 입력해주세요.'
                                       defaultValue={bar?.contact}/>
                    <MaterialTextField ref={instagramRef} icon={<InstagramIcon/>} placeholder='인스타그램 아이디를 입력해주세요.'
                                       defaultValue={bar?.instagram}/>
                    {bar?.instagram && (
                        <MaterialButton onClick={openInstagramPage}>
                            {bar?.instagram_access_token ? '인스타그램 연동 완료' : '인스타그램 연동'}
                        </MaterialButton>
                    )}
                </CardView>

                <CardView css={cardStyle} title='영업시간'>
                    <div css={openingHourContainerStyle}>
                        {bar?.opening_hours?.map((e) => (
                            <span key={e} css={openingHourStyle}>{e}</span>
                        )) ?? <span css={noOpeningHourStyle}>정보 없음</span>}
                        <MaterialButton style={{marginTop: '20px'}}
                                        endIcon={(<ClockIcon color={colors.font.default}/>)}
                                        onClick={() => setOpenOpeningHourDialog(true)}>영업시간 수정</MaterialButton>
                    </div>
                </CardView>

                <CardView css={cardStyle} title='태그'>
                    <IntroduceContainer icon={<TagIcon/>} placeholder='태그를 입력해주세요.'
                                        onAddHandler={() => setOpenTagDialog(true)}>
                        {bar?.tag && `#${bar?.tag?.join(' #')}`}
                    </IntroduceContainer>
                </CardView>

                <CardView css={cardStyle} title='메뉴'>
                    <IntroduceContainer icon={<RestaurantIcon/>} placeholder='메뉴를 입력해주세요.'
                                        onAddHandler={() => setOpenMenuDialog(true)}>
                        {bar?.menu?.join(', ')}
                    </IntroduceContainer>
                </CardView>

                <CardView css={cardStyle} title='소개글'>
                    <MaterialTextField ref={introduceOwnerRef} placeholder='업소에 대한 소개글을 입력해주세요.' rows={4}
                                       defaultValue={bar?.introduce_owner}
                                       multiline/>
                </CardView>

                <MaterialButton onClick={onSubmitHandler}>등록하기</MaterialButton>

                <OpeningHourDialog barId={bar?.id} open={openOpeningHourDialog}
                                   onClose={() => setOpenOpeningHourDialog(false)}/>
                <TagDialog barId={bar?.id} initialTag={bar?.tag ?? []} open={openTagDialog}
                           onClose={() => setOpenTagDialog(false)}/>
                <MenuDialog barId={bar?.id} initialMenu={bar?.menu ?? []} open={openMenuDialog}
                            onClose={() => setOpenMenuDialog(false)}/>
                <TipxyDialog open={!!errorMessage} onSubmitHandler={() => setErrorMessage('')}>
                    {errorMessage}
                </TipxyDialog>
                <LoadingDialog open={isLoading}/>
            </MainLayout>
        </TopBarLayout>
    );
}

export default ShopPage;