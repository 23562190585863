import {configureStore, createReducer, createAction} from "@reduxjs/toolkit";

const SET_LOCATION_LIST = 'SET_LOCATION_LIST';
const SET_BAR = 'SET_BAR';
const SET_BAR_LIST = 'SET_BAR_LIST';
const SET_BAR_OPENING_HOURS = 'SET_BAR_OPENING_HOURS';
const SET_BAR_TAG = 'SET_BAR_TAG';
const SET_BAR_MENU = 'SET_BAR_MENU';

const reducer = createReducer({location: [], bar: []}, {
    [SET_LOCATION_LIST]: (state, action) => {
        state.location = action.payload;
    },
    [SET_BAR]: (state, action) => {
        state.bar[action.payload.index] = action.payload.bar
    },
    [SET_BAR_LIST]: (state, action) => {
        state.bar = action.payload;
    },
    [SET_BAR_OPENING_HOURS]: (state, action) => {
        state.bar.find((e) => e.id === action.payload.id).opening_hours = action.payload.openingHours
    },
    [SET_BAR_TAG]: (state, action) => {
        state.bar.find((e) => e.id === action.payload.id).tag = action.payload.tags
    },
    [SET_BAR_MENU]: (state, action) => {
        state.bar.find((e) => e.id === action.payload.id).menu = action.payload.menus
    }
})

export const BarActions = {
    setLocationList: createAction(SET_LOCATION_LIST),
    setBar: createAction(SET_BAR),
    setBarList: createAction(SET_BAR_LIST),
    setBarOpeningHours: createAction(SET_BAR_OPENING_HOURS),
    setBarTag: createAction(SET_BAR_TAG),
    setBarMenu: createAction(SET_BAR_MENU)
}

export default configureStore({reducer});