import {CircularProgress, Dialog} from "@mui/material";
import {css} from "@emotion/react";

const progressStyle = css`
    margin: 50px;
`;

const LoadingDialog = (props) => {
    return (
        <Dialog {...props}>
            <CircularProgress css={progressStyle}/>
        </Dialog>
    );
}

export default LoadingDialog;