import TopBarLayout from "../components/Layout/TopBarLayout";
import MainLayout from "../components/Layout/MainLayout";
import MaterialSelect from "../components/MaterialSelect";
import ChevronDownIcon from "../icons/ChevronDownIcon";
import {Avatar, MenuItem} from "@mui/material";
import colors from "../styles/Colors";
import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {deleteChat, getChatList} from "../lib/api/chat";
import {css} from "@emotion/react";
import moment from "moment";
import DeleteDialog from "../components/Dialog/DeleteDialog";

const chatStyle = css`
  font-size: 12px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: end;
`;

const chatContentStyle = css`
  margin-left: 12px;
`;

const chatBubbleStyle = css`
  padding: 10px 8px;
  font-size: 16px;
  background-color: ${colors.primary};
  border-radius: 12px 12px 12px 0;
`;

const dateStyle = css`
  font-size: 12px;
  margin-left: 4px;
`;

const descriptionTextStyle = css`
  margin: 40px;
  text-align: center;
  color: ${colors.font.dim300};
`;

const ChatListPage = () => {
    const barList = useSelector(state => state.bar);

    const [bar, setBar] = useState(barList[0]?.id);
    const [chatList, setChatList] = useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const dateFormatter = (value) => moment(value).format("HH:mm");
    const selectChatId = useRef();

    useEffect(() => {
        setBar(barList[0]?.id);
    }, [barList]);

    useEffect(() => {
        const chatroomId = barList.find((e) => e.id === bar)?.chatroom_id

        if (chatroomId) {
            getChatList(chatroomId, 0)
                .then((res) => setChatList(res));
        }
    }, [bar]);

    const deleteChatHandler = () => {
        deleteChat(selectChatId.current)
            .then(() => {
                setChatList(chatList => chatList.filter((e) => e.id !== selectChatId.current))
                setOpenDeleteDialog(false);
            })
    }

    return (
        <TopBarLayout>
            <MainLayout>
                {barList.length > 1 && (
                    <MaterialSelect arrowIcon={<ChevronDownIcon/>} defaultValue={barList[0]?.id}
                                    onChange={({target}) => setBar(target.value)} fullWidth>
                        {barList.map((e) => (
                            <MenuItem key={e.id} value={e.id} style={{color: colors.font.default}}>{e.name}</MenuItem>
                        ))}
                    </MaterialSelect>
                )}
                {barList.find((e) => e.id === bar)?.chatroom_id ?
                    (<div>
                        {chatList.map((chat) => (
                            <div css={chatStyle} key={chat.id} onClick={() => {
                                selectChatId.current = chat.id;
                                setOpenDeleteDialog(true);
                            }}>
                                <Avatar
                                    sx={{bgcolor: colors.profileBgColor[chat.user_id % colors.profileBgColor.length]}}
                                    src={process.env.REACT_APP_TIPXY_S3_URL + chat.profile}>{chat.profile}</Avatar>
                                <div css={chatContentStyle}>
                                    {chat.nickname}
                                    <div css={chatBubbleStyle}>{chat.content}</div>
                                </div>
                                <span css={dateStyle}>{dateFormatter(chat.created_time)}</span>
                            </div>
                        ))}
                    </div>) :
                    (<div css={descriptionTextStyle}>
                        채팅방이 없습니다.
                    </div>)
                }

                <DeleteDialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}
                              closeDeleteDialogHandler={() => setOpenDeleteDialog(false)}
                              deleteHandler={deleteChatHandler}/>
            </MainLayout>
        </TopBarLayout>
    );
}

export default ChatListPage;