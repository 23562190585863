import MenuButton from "./MenuButton";
import {css} from "@emotion/react";
import colors from "../styles/Colors";
import {MOBILE_WIDTH} from "../Constants";
import {useDispatch, useSelector} from "react-redux";
import {BarActions} from "../store/barStore";
import {useEffect} from "react";
import {getUserBarList} from "../lib/api/user";
import {getBarLocationList} from "../lib/api/bar";

const menuStyle = (isShowing) => css`
  width: 100%;
  display: ${isShowing ? 'block' : 'none'};
  position: absolute;
  z-index: 999;

  @media (min-width: ${MOBILE_WIDTH}) {
    width: 180px;
    height: 100vh;
    display: block;
    position: sticky;
    top: 0;
    background-color: ${colors.background.light};
  }
`

const SideMenu = ({showMenu, setShowMenu}) => {
    const bars = useSelector(state => state.bar);
    const dispatch = useDispatch();

    useEffect(() => {
        getUserBarList().then((res) => {
            dispatch(BarActions.setBarList(res));
        });

        getBarLocationList().then((response) => {
            dispatch(BarActions.setLocationList(response));
        });
    }, []);

    return (
        <div css={menuStyle(showMenu)}>
            {bars.map((bar, index) => (
                <MenuButton key={bar.id} to={`/shop/${index}`} setShowMenu={setShowMenu}>{bar.name}</MenuButton>
            ))}
            <MenuButton to='/chat' setShowMenu={setShowMenu}>채팅 관리</MenuButton>
            <MenuButton to='/password' setShowMenu={setShowMenu}>비밀번호 변경</MenuButton>
        </div>
    )
}

export default SideMenu;