import LogoIcon from "../icons/LogoIcon";
import {css} from "@emotion/react";
import colors from "../styles/Colors";
import {Link} from "react-router-dom";
import MenuIcon from "../icons/MenuIcon";
import buttonHoverStyle from "../styles/Styles";
import {MOBILE_WIDTH} from "../Constants";

const menuButtonStyle = css`
  background: transparent;
  ${buttonHoverStyle};
  
  @media (min-width: ${MOBILE_WIDTH}) {
    display: none;
  }
`

const topBarStyle = css`
  display: flex;
  justify-content: space-between;
  background: ${colors.primary};
  align-items: center;
`

const logoContainerStyle = css`  
  @media(min-width: ${MOBILE_WIDTH}) {
    padding: 12px 16px;
  }
`

const signOutButtonStyle = css`
  height: auto;
  padding: 0 12px;
  color: ${colors.font.default};
  text-decoration: none;
`

const TopBar = ({menuButtonClickHandler}) => {
    return (
        <div css={topBarStyle}>
            <button css={menuButtonStyle} onClick={menuButtonClickHandler}>
                <MenuIcon/>
            </button>
            <div css={logoContainerStyle}>
                <Link to='/shop'>
                    <LogoIcon/>
                </Link>
            </div>
            <Link css={signOutButtonStyle} to='/'>
                로그아웃
            </Link>
        </div>
    );
}

export default TopBar;