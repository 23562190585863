import {fetchGet, fetchPut} from "../fetchLib";

export const getUserBarList = async () => {
    const response = await fetchGet({
        url: `/api/private/user/bar`
    });

    return response.item;
}

export const updateUserPassword = async (body) => {
    const response = await fetchPut({
        url: `/api/private/user/password`,
        body,
    });

    return response;
}