import colors from "../styles/Colors";

const ClockIcon = ({color = colors.font.dim300, ...props}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
            <path
                d="M9.99517 20C15.4761 20 20 15.4739 20 10C20 4.52611 15.4664 0 9.9855 0C4.51426 0 0 4.52611 0 10C0 15.4739 4.52392 20 9.99517 20ZM9.99517 18.0077C5.55824 18.0077 2.01063 14.4391 2.01063 10C2.01063 5.56093 5.55824 2.00193 9.9855 2.00193C14.4224 2.00193 17.9894 5.56093 17.999 10C18.0087 14.4391 14.4321 18.0077 9.99517 18.0077ZM5.12325 11.2186H9.9855C10.4302 11.2186 10.7685 10.8801 10.7685 10.4449V4.11992C10.7685 3.68472 10.4302 3.34623 9.9855 3.34623C9.56017 3.34623 9.22185 3.68472 9.22185 4.11992V9.67118H5.12325C4.68825 9.67118 4.34993 10.0097 4.34993 10.4449C4.34993 10.8801 4.68825 11.2186 5.12325 11.2186Z"
                fill={color}/>
        </svg>
    );
}

export default ClockIcon;