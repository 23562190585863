import TipxyDialog from "./TipxyDialog";
import MaterialTextField from "../MaterialTextField";
import colors from "../../styles/Colors";
import React, {useEffect, useRef, useState} from "react";
import {css} from "@emotion/react";
import {Button} from "@mui/material";
import AddButton from "../AddButton";
import {updateBarTag} from "../../lib/api/bar";
import {BarActions} from "../../store/barStore";
import {useDispatch} from "react-redux";

const textStyle = css`
  color: ${colors.font.dim300};
  font-size: 12px;
  margin-top: 10px;
  text-align: center;
`;

const textFieldStyle = css`
  position: relative;
`;

const addButtonStyle = css`
  position: absolute;
  right: 10px;
  top: 25%;
`;

const tagListContainerStyle = css`
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
`;

const tagStyle = css`
  margin: 4px;
  border-radius: 200px;
  border: ${colors.opacity.white20} solid 1px;
  background-color: ${colors.background.default};
  color: ${colors.font.default};
  font-weight: 700;
`;

const TagDialog = ({barId, initialTag, open, onClose}) => {
    const [tags, setTags] = useState(initialTag);

    const dispatch = useDispatch();
    const tagRef = useRef();

    const addTagHandler = () => {
        if (tagRef.current.value) {
            setTags(tags => [...tags, tagRef.current.value.replaceAll(' ', '').replaceAll('#', '')]);
            tagRef.current.value = null;
        }
    }

    const removeTagHandler = ({target}) => {
        setTags(tags => tags.filter((tag) => `#${tag}` !== target.innerText));
    }

    const updateTagHandler = () => {
        Promise.all(tags.map((e, index) => {
            const originalTag = initialTag[index];

            if (e !== originalTag) {
                const request = {
                    bar_id: barId,
                    original_tag: originalTag,
                    tag: e
                };

                return updateBarTag(request);
            } else return undefined
        })).then(() => {
            dispatch(BarActions.setBarTag({
                id: barId,
                tags: tags
            }));
            onClose();
        }).finally(() => {
            // setIsLoading(false);
        });
    }

    useEffect(() => {
        if (open) {
            setTags(initialTag);
        }
    }, [open]);

    return (
        <TipxyDialog open={open} onClose={onClose} onSubmitHandler={updateTagHandler}>
            <div css={textFieldStyle}>
                <MaterialTextField backgroundColor={colors.background.default}
                                   placeholder='태그를 입력해주세요' ref={tagRef} maxLength={10} fullWidth/>
                <AddButton css={addButtonStyle} onClick={addTagHandler}/>
            </div>
            <div css={textStyle}>태그는 최대 10자, 5개까지 추가하실 수 있습니다.</div>
            <div css={tagListContainerStyle}>
                {tags.map((tag) => <Button key={tag} css={tagStyle} onClick={removeTagHandler}>#{tag}</Button>)}
            </div>
        </TipxyDialog>
    );
}

export default TagDialog;