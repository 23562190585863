import {Route, Routes} from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import ShopPage from "./pages/ShopPage";
import {createTheme, ThemeProvider} from "@mui/material";
import colors from "./styles/Colors";
import {red} from "@mui/material/colors";
import ChatListPage from "./pages/ChatListPage";
import ChangePasswordPage from "./pages/ChangePasswordPage";

const theme = createTheme({
    palette: {
        primary: {
            main: colors.primary,
            dark: colors.primary_hover,
        },
        secondary: {
            main: colors.secondary,
        },
        warning: {
            main: red[500]
        },
        background: {
            default: colors.background.default,
            paper: colors.background.light,
        },
    },
})

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Routes>
                <Route path='/' element={<LoginPage/>}/>
                <Route path='/shop' element={<ShopPage/>}/>
                <Route path='/shop/:index' element={<ShopPage/>}/>
                <Route path='/chat' element={<ChatListPage/>}/>
                <Route path='/chat/:index' element={<ShopPage/>}/>
                <Route path='/password' element={<ChangePasswordPage/>}/>
            </Routes>
        </ThemeProvider>
    );
}

export default App;
