import TipxyDialog from "./TipxyDialog";
import MaterialTextField from "../MaterialTextField";
import colors from "../../styles/Colors";
import React, {useEffect, useRef, useState} from "react";
import {css} from "@emotion/react";
import {Button} from "@mui/material";
import AddButton from "../AddButton";
import {updateBarMenu} from "../../lib/api/bar";
import {useDispatch} from "react-redux";
import {BarActions} from "../../store/barStore";

const textStyle = css`
  color: ${colors.font.dim300};
  font-size: 12px;
  margin-top: 10px;
  text-align: center;
`;

const textFieldStyle = css`
  position: relative;
`;

const addButtonStyle = css`
  position: absolute;
  right: 10px;
  top: 25%;
`;

const menuListContainerStyle = css`
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
`;

const menuStyle = css`
  margin: 4px;
  border-radius: 200px;
  border: ${colors.opacity.white20} solid 1px;
  background-color: ${colors.background.default};
  color: ${colors.font.default};
  font-weight: 700;
`;

const MenuDialog = ({barId, initialMenu, open, onClose}) => {
    const [menus, setMenus] = useState(initialMenu);

    const dispatch = useDispatch();
    const menuRef = useRef();

    const addMenuHandler = () => {
        if (menuRef.current.value) {
            setMenus(tags => [...tags, menuRef.current.value]);
            menuRef.current.value = null;
        }
    }

    const removeMenuHandler = ({target}) => {
        setMenus(tags => tags.filter((menu) => menu !== target.innerText));
    }

    const updateTagHandler = () => {
        Promise.all(menus.map((e, index) => {
            const originalName = initialMenu[index];

            if (e !== originalName) {
                const request = {
                    bar_id: barId,
                    original_name: originalName,
                    name: e
                };

                return updateBarMenu(request);
            } else return undefined
        })).then(() => {
            dispatch(BarActions.setBarMenu({
                id: barId,
                menus: menus
            }));
            onClose();
        }).finally(() => {
            // setIsLoading(false);
        });
    }

    useEffect(() => {
        if (open) {
            setMenus(initialMenu);
        }
    }, [open]);

    return (
        <TipxyDialog open={open} onClose={onClose} onSubmitHandler={updateTagHandler}>
            <div css={textFieldStyle}>
                <MaterialTextField backgroundColor={colors.background.default}
                                   placeholder='메뉴명을 입력해주세요' ref={menuRef} maxLength={20} fullWidth/>
                <AddButton css={addButtonStyle} onClick={addMenuHandler}/>
            </div>
            <div css={textStyle}>사용자의 선택을 많이 받은 메뉴 3개가 소개에 노출됩니다.<br/>실제로 제공되는 메뉴를 등록해주세요.<br/>(주류제외, 최대 20자)</div>
            <div css={menuListContainerStyle}>
                {menus.map((menu) => <Button key={menu} css={menuStyle} onClick={removeMenuHandler}>{menu}</Button>)}
            </div>
        </TipxyDialog>
    );
}

export default MenuDialog;