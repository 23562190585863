import {fetchDelete, fetchGet, getQueryString} from "../fetchLib";

export const getChatList = async (chatroom_id, page) => {
    const response = await fetchGet({
        url: `/api/private/chat/list${getQueryString({chatroom_id, page})}`
    });

    return response.item;
}

export const deleteChat = async (chat_id) => {
    const response = await fetchDelete({
        url: `/api/private/chat`,
        body: {chat_id}
    });

    return response;
}