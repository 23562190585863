import {fetchPost, fetchPut} from "../fetchLib";
import crypto from 'crypto';

const encryptPassword = (password) => crypto.createHash('sha256').update(password).digest('base64');

export const signInWithEmail = async (email, password) => {
    const response = await fetchPost({
        url: `/api/public/auth`,
        body: {
            email: email,
            password: encryptPassword(password),
        },
    });

    return response.item;
}

export const refreshToken = (user_id) => {
    return fetchPut({
        url: `/api/public/auth/refresh`,
        body: {user_id},
    });
}