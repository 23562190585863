const LogoIcon = ({size = 1}) => (
    <svg width={95 * size} height={38 * size} viewBox="0 0 95 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.8735 29.1759L12.9563 24.5949C12.2683 24.7451 10.8542 24.8953 9.89875 24.8577C8.90506 24.8202 8.21711 24.2945 8.21711 23.0929V13.8557H13.5296V9.5H8.21711V2.47826H3.47794V9.5H0V13.8557H3.47794V23.0929C3.47794 26.6601 6.65013 29.4012 9.74588 29.5138C11.0835 29.5514 12.9563 29.3261 13.8735 29.1759Z" fill="#F1F1F1"/>
        <path d="M18.1401 9.5751V29.6265H22.6118V9.5751H18.1401ZM17.8726 2.44071C17.8726 3.83004 18.7517 4.91897 20.3569 4.91897C22.0003 4.91897 22.8793 3.83004 22.8793 2.44071C22.8793 1.08893 22.0003 0 20.3569 0C18.7517 0 17.8726 1.08893 17.8726 2.44071Z" fill="#F1F1F1"/>
        <path d="M38.8287 25.5711C35.8859 25.5711 33.3252 22.9802 33.3252 19.6008C33.3252 16.2589 35.8859 13.7055 38.8287 13.7055C42.3067 13.7055 44.3705 16.2589 44.3705 19.6008C44.3705 22.9802 42.3067 25.5711 38.8287 25.5711ZM28.5478 38H33.1341V26.5099C34.5482 28.9881 37.7968 29.664 39.1727 29.664C45.0967 29.664 48.8039 25.0455 48.8039 19.6008C48.8039 14.2312 45.0967 9.5751 39.1727 9.5751C37.835 9.5751 34.5482 10.3261 33.1341 12.6917V9.5751H28.5478V38Z" fill="#F1F1F1"/>
        <path d="M71.5841 9.7253H66.0806L61.3032 16.1087L56.4876 9.7253H50.984L58.4367 19.6383L51.1751 29.5138H56.6404L61.3032 23.3182L66.0041 29.5138H71.4312L64.0932 19.6383L71.5841 9.7253Z" fill="#F1F1F1"/>
        <path d="M82.8463 38L87.3562 27.336L95 9.61265H89.8786L84.4897 22.0791L79.0626 9.61265H73.8648L82.0819 27.7115L77.7249 38H82.8463Z" fill="#F1F1F1"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M94 4H29V0H94V4Z" fill="#F1F1F1"/>
    </svg>
)

export default LogoIcon;