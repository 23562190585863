import LogoIcon from "../icons/LogoIcon";
import {css} from "@emotion/react";
import colors from "../styles/Colors";
import React, {useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import MaterialTextField from "../components/MaterialTextField";
import MaterialButton from "../components/MaterialButton";
import LoadingDialog from "../components/Dialog/LoadingDialog";
import {signInWithEmail} from "../lib/api/auth";
import {getUserBarList} from "../lib/api/user";
import {BarActions, setBarList} from "../store/barStore";
import {useDispatch} from "react-redux";

const rootStyle = css`
  height: 100vh;
  background-color: ${colors.background.default};
  color: ${colors.font.default};
`

const containerStyle = css`
  margin: auto;
  text-align: center;
  max-width: 360px;
  padding-top: 140px;
`

const textFieldStyle = css`
  margin-bottom: 20px;
  width: 100%;
`

const errorMessageStyle = css`
  display: block;
  margin-top: 24px;
  color: red;
`

const LoginPage = () => {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const emailRef = useRef();
    const passwordRef = useRef();

    const clickLoginButtonHandler = async () => {
        setIsLoading(true);
        setErrorMessage(null);

        await signInWithEmail(emailRef.current.value, passwordRef.current.value)
            .then(response => {
                if (response.type !== 1) setErrorMessage('사장님 권한이 없습니다.')
                else {
                    localStorage.setItem('user_id', response.id);
                    localStorage.setItem('access_token', response.access_token);
                    localStorage.setItem('refresh_token', response.refresh_token);
                    navigate('/shop');
                }
            })
            .catch(e => setErrorMessage(e.response.data.message));
        setIsLoading(false);
    }

    return (
        <div css={rootStyle}>
            <div css={containerStyle}>
                <LogoIcon size={1.4}/>
                <p>사장님 페이지</p>
                <MaterialTextField css={textFieldStyle} ref={emailRef} type="email" placeholder="이메일을 입력해 주세요."/>
                <MaterialTextField css={textFieldStyle} ref={passwordRef} type="password"
                                   placeholder="비밀번호를 입력해 주세요."/>
                <MaterialButton onClick={clickLoginButtonHandler}>로그인</MaterialButton>
                <span css={errorMessageStyle}>{errorMessage}</span>
            </div>

            <LoadingDialog open={isLoading}/>
        </div>
    );
}

export default LoginPage;