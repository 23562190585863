import {fetchDelete, fetchGet, fetchPost, fetchPut, getQueryString} from "../fetchLib";

export const updateBar = async (request) => {
    const response = await fetchPut({
        url: `/api/private/bar`,
        body: request
    });

    return response.item;
}

export const getBarLocationList = async () => {
    const response = await fetchGet({
        url: `/api/public/bar/location`
    });

    return response.item;
}

export const getOpeningHours = async (bar_id) => {
    const response = await fetchGet({
        url: `/api/public/bar/opening-hours${getQueryString({bar_id})}`
    });

    return response.item;
}

export const createOpeningHours = async (request) => {
    const response = await fetchPost({
        url: `/api/private/bar/opening-hours`,
        body: request
    });

    return response.item;
}

export const deleteOpeningHours = async (bar_id) => {
    const response = await fetchDelete({
        url: `/api/private/bar/opening-hours`,
        body: {bar_id}
    });

    return response.item;
}

export const updateBarTag = async (request) => {
    const response = await fetchPut({
        url: `/api/private/bar/tag`,
        body: request
    });

    return response;
}

export const updateBarMenu = async (request) => {
    const response = await fetchPut({
        url: `/api/private/bar/menu`,
        body: request
    });

    return response;
}