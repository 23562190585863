import {fetchPost} from "../fetchLib";

export const uploadImage = async (file, type) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);

    const response = await fetchPost({
        url: `/api/private/image`,
        body: formData
    });

    return response.item;
}