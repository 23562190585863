import {css, IconButton} from "@mui/material";
import React from "react";
import colors from "../styles/Colors";
import AddIcon from "../icons/AddIcon";
import AddButton from "./AddButton";

const roundContainerStyle = css`
  display: flex;
  color: ${colors.font.dim300};
  background-color: ${colors.background.light};
  flex-direction: row;
  align-items: center;
  border-radius: 200px;
  color: ${colors.font.default};

  & > svg {
    margin: 14px;
  }
`;

const contentStyle = css`
  flex-grow: 1;
`;

const placeholderStyle = css`
  flex-grow: 1;
  color: ${colors.font.dim300};
`;

const addButtonStyle = css`
  margin: 9px 11px;
`;

const IntroduceContainer = ({icon, placeholder, children, onAddHandler, ...props}) => {
    return (
        <div css={roundContainerStyle} {...props}>
            {icon}
            {children ? (<span css={contentStyle}>{children}</span>)
                : (<span css={placeholderStyle}>{placeholder}</span>)}
            <AddButton css={addButtonStyle} onClick={onAddHandler}/>
        </div>
    );
}

export default IntroduceContainer;