import {InputAdornment, TextField} from "@mui/material";
import colors from "../styles/Colors";
import {css} from "@emotion/react";
import {forwardRef} from "react";

const textFieldStyle = (multiline, backgroundColor) => css`
  & .MuiInputLabel-root {
    &.MuiInputLabel-shrink {
      color: ${colors.primary};
    }
  }

  .textField {
    color: ${colors.font.default};
    background-color: ${backgroundColor ?? colors.background.light};
    border-radius: ${multiline ? '20px' : '200px'};
  }
`;

const MaterialTextField = forwardRef(({label, icon, arrowIcon, backgroundColor, maxLength, ...props}, ref) => {
    const inputProps = {
        className: 'textField',
        startAdornment: (
            <InputAdornment position='start'>
                {icon}
            </InputAdornment>
        ),
    };

    return (
        <TextField
            css={textFieldStyle(props.multiline, backgroundColor)}
            label={label}
            inputRef={ref}
            InputProps={inputProps}
            inputProps={{maxLength: maxLength}}
            {...props}
        />
    );
});

export default MaterialTextField;