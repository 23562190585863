import axios from "axios";
import qs from "qs";
import {refreshToken} from "./api/auth";

const getFetchOptions = ({method, headers, body}) => {
    if (!method) return;

    const options = {
        method,
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            'X-API-Version': '1.0',
            'X-Access-Token': localStorage.getItem('access_token'),
            'X-Refresh-Token': localStorage.getItem('refresh_token'),
        },
        data: body,
    };

    if (body instanceof FormData) {
        options.data = body;
    } else if (!body) {
        options.data = JSON.stringify(body);
    }

    if (!headers)
        options.headers = Object.assign((options.headers || {}), headers);

    return options;
};

export const checkStatus = response => {
    const parsedResponse = response.data;

    if (!response.statusText === 'OK') {
        const error = new Error(response.statusText);
        error.response = parsedResponse;

        throw error;
    }

    return parsedResponse;
};

const fetchByMethod = ({method, url, headers, body}) => {
    if (!url) throw new Error('fetch url is not exists');
    if (!method) throw new Error('method is not exists');

    const axiosConfig = {
        url: url,
        baseURL: process.env.REACT_APP_TIPXY_API_URL,
        ...getFetchOptions({method, headers, body})
    }

    return axios(axiosConfig)
        .then(checkStatus)
        .catch(handleError);
};

const handleError = (e) => {
    const errorCode = e.response.data.code;
    if (errorCode === 5002) {
        const request = e.config;

        return refreshToken(localStorage.getItem('user_id'))
            .then(response => {
                const tokens = response.item;
                localStorage.setItem('access_token', tokens.access_token);
                localStorage.setItem('refresh_token', tokens.refresh_token);

                request.headers['X-Access-Token'] = localStorage.getItem('access_token');
                request.headers['X-Refresh-Token'] = localStorage.getItem('refresh_token');

                return axios(request)
                    .then(checkStatus)
                    .catch(e => {
                        throw e;
                    });
            });
    }

    throw e;
}

export const fetchGet = (options = {}) => fetchByMethod({method: 'GET', ...options});
export const fetchPost = (options = {}) => fetchByMethod({method: 'POST', ...options});
export const fetchPut = (options = {}) => fetchByMethod({method: 'PUT', ...options});
export const fetchDelete = (options = {}) => fetchByMethod({method: 'DELETE', ...options});

// param
export const getQueryString = o => {
    const queryString = o && `${qs.stringify(o)}`;
    return queryString ? `?${queryString}` : '';
}