import {Button} from "@mui/material";
import {css} from "@emotion/react";

const buttonStyle = css`
  height: 48px;
  border-radius: 24px;
  font-size: 18px;
  font-weight: 700;
`;

const MaterialButton = ({...props}) => {
    return (
        <Button
            css={buttonStyle}
            variant='contained'
            fullWidth
            {...props}/>
    );
}

export default MaterialButton;