import TopBar from "../TopBar";
import SideMenu from "../SideMenu";
import {useState} from "react";
import {css} from "@emotion/react";
import {MIN_WIDTH, MOBILE_WIDTH} from "../../Constants";

const rootStyle = css`
  min-width: ${MIN_WIDTH};
`;

const containerStyle = css`
  display: flex;
  flex-direction: column;

  @media (min-width: ${MOBILE_WIDTH}) {
    flex-direction: row;
  }
`;

const contentStyle = css`
    flex-grow: 1;
`;

const TopBarLayout = ({children}) => {
    const [showMenu, setShowMenu] = useState(false)

    const menuButtonClickHandler = () => {
        setShowMenu(showMenu => !showMenu)
    }

    return (
        <div css={rootStyle}>
            <TopBar menuButtonClickHandler={menuButtonClickHandler}/>
            <div css={containerStyle}>
                <SideMenu showMenu={showMenu} setShowMenu={setShowMenu}/>
                <div css={contentStyle}>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default TopBarLayout;