import {css} from "@emotion/react";
import colors from "../styles/Colors";

const titleStyle = css`
  font-size: 12px;
  font-weight: 700;
  color: ${colors.font.dim300};
`;

const subtitleStyle = css`
  font-size: 12px;
  color: ${colors.font.dim300};
  margin-bottom: 10px;
`;

const cardStyle = css`
  display: flex;
  border-radius: 8px;
  flex-direction: column;
`;

const CardView = ({title, subtitle, children, ...props}) => {
    return (
        <div css={cardStyle} {...props}>
            <span css={titleStyle}>{title}</span>
            <span css={subtitleStyle}>{subtitle}</span>
            {children}
        </div>
    );
}

export default CardView;