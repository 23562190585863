import {InputAdornment, Select} from "@mui/material";
import {css} from "@emotion/react";
import colors from "../styles/Colors";

const selectStyle = css`
  min-width: 80px;
  color: ${colors.font.default};
  background-color: ${colors.background.light};
  border-radius: 200px;

  &.MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: ${colors.primary};
    }
  }
`;

const MaterialSelect = ({icon, children, arrowIcon, ...props}) => {
    const iconComponent = () => (
        <span style={{marginRight: '20px'}}>{arrowIcon}</span>
    );

    const adornment = (
        <InputAdornment position='start'>
            {icon}
        </InputAdornment>
    );

    return (
        <Select
            css={selectStyle}
            startAdornment={adornment}
            IconComponent={iconComponent}
            {...props}>
            {children}
        </Select>
    );
}

export default MaterialSelect;