import MaterialTextField from "./MaterialTextField";
import React, {useState} from "react";
import {css} from "@emotion/react";
import WeekdayToggleButton from "./WeekdayToggleButton";
import colors from "../styles/Colors";
import {IconButton} from "@mui/material";
import DismissIcon from "../icons/DismissIcon";

const rootStyle = css`
  border-bottom: ${colors.opacity.white10} solid 1px;
  margin-bottom: 18px;
`;

const weekdaysStyle = css`
  display: flex;
  justify-content: space-between;
`;

const timeInputContainerStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  margin: 12px 0 14px 0;
  color: ${colors.font.dim300};
`;

const timeInputStyle = css`
  flex-grow: 1;
  margin-left: 8px;
`;

const checkBoxCellStyle = css`
  text-align: center;
`;

const OpeningHourInput = ({openingHour, onOpenTimeChange, onCloseTimeChange, onWeekdaysChange, onDelete, isLast}) => {
    const weekdays = [64, 32, 16, 8, 4, 2, 1];
    const weekdayName = ['월', '화', '수', '목', '금', '토', '일']
    const [checkedWeekdays, setCheckedWeekdays] = useState(openingHour?.weekday ?? 0);

    return (
        <div css={rootStyle}>
            <div css={weekdaysStyle}>
                {weekdays.map((weekday, index) => (
                    <span key={`${openingHour?.id}-${weekday}`} css={checkBoxCellStyle}>
                        <WeekdayToggleButton weekday={weekdayName[index]}
                                             defaultChecked={(openingHour?.weekday & weekday) === weekday}
                                             onChange={(checked) => {
                                                 setCheckedWeekdays((checkedWeekdays) => {
                                                     const sumWeekdays = checkedWeekdays + weekday * (checked ? 1 : -1);
                                                     onWeekdaysChange(sumWeekdays);
                                                     return sumWeekdays;
                                                 });
                                             }}/>
                    </span>
                ))}
                {isLast ? <div style={{width: '31px'}}/> : (<IconButton onClick={onDelete}><DismissIcon/></IconButton>)}
            </div>
            <div css={timeInputContainerStyle}>
                <span style={{minWidth: '60px'}}>문 여는 시간</span>
                <MaterialTextField css={timeInputStyle} type={'time'} defaultValue={openingHour?.open_time}
                                   onChange={onOpenTimeChange} backgroundColor={colors.background.default}/>
                <span style={{minWidth: '70px', marginLeft: '12px'}}>~ 문 닫는 시간</span>
                <MaterialTextField css={timeInputStyle} type={'time'} defaultValue={openingHour?.close_time}
                                   onChange={onCloseTimeChange} backgroundColor={colors.background.default}/>
            </div>
        </div>
    );
}

export default OpeningHourInput;