import MaterialButton from "../MaterialButton";
import React, {useEffect, useState} from "react";
import {createOpeningHours, deleteOpeningHours, getOpeningHours} from "../../lib/api/bar";
import LoadingDialog from "./LoadingDialog";
import OpeningHourInput from "../OpeningHourInput";
import {css} from "@emotion/react";
import AddIcon from "../../icons/AddIcon";
import TipxyDialog from "./TipxyDialog";
import {MOBILE_WIDTH} from "../../Constants";
import {useDispatch} from "react-redux";
import {BarActions} from "../../store/barStore";

const errorMessageStyle = css`
  color: red;
  text-align: center;
  margin-bottom: 12px;
`;

const addButtonStyle = css`
  margin: 0 auto;

  @media (min-width: ${MOBILE_WIDTH}) {
    margin: 0 94px;
  }
`;

const OpeningHourDialog = ({barId, open, onClose}) => {
    const weekdays = [64, 32, 16, 8, 4, 2, 1];

    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [openingHours, setOpeningHours] = useState([]);
    const [addedOpeningHours, setAddedOpeningHours] = useState([{bar_id: barId}]);

    const dispatch = useDispatch();

    useEffect(() => {
        setError(null);
        setAddedOpeningHours([{bar_id: barId}]);

        if (open) {
            getOpeningHours(barId).then((response) => {
                setOpeningHours(response);
            }).finally(() => setIsLoading(false))
        }
    }, [open]);

    const checkValidation = (requestOpeningHours) => {
        let errorMessage = null;

        weekdays.forEach((weekday) => {
            if (requestOpeningHours.map((e) => e.weekday & weekday).filter((e) => e > 0).length > 1) {
                errorMessage = '겹치는 요일이 있습니다';
            }
        })

        if (!errorMessage) {
            requestOpeningHours.forEach((e) => {
                if (!(e.weekday && e.open_time && e.close_time)) {
                    errorMessage = '설정 안 된 시간이 있습니다';
                }
            })
        }

        setError(errorMessage);
        return !errorMessage;
    }

    const onSubmitClickHandler = () => {
        const requestOpeningHours = [...openingHours, ...addedOpeningHours];

        if (checkValidation(requestOpeningHours)) {
            setAddedOpeningHours(openingHours => [...openingHours, {bar_id: barId}]);
        }
    }

    const onSubmitHandler = () => {
        const requestOpeningHours = [...openingHours, ...(addedOpeningHours.slice(0, addedOpeningHours.length - 1))];

        if (checkValidation(requestOpeningHours)) {
            setIsLoading(true);
            deleteOpeningHours(barId).then(() => {
                Promise.all(
                    requestOpeningHours.map((e) => createOpeningHours(e))
                ).then((res) => {
                    setError(null);

                    dispatch(BarActions.setBarOpeningHours({
                        id: barId,
                        openingHours: res.pop()
                    }))
                    onClose();
                }).catch((e) => {
                    setError(e);
                }).finally(() => {
                    setIsLoading(false);
                });
            }).catch((e) => {
                setError(e);
                setIsLoading(false);
            });
        }
    }

    const onCloseHandler = () => {
        onClose();
    }

    return (
        <TipxyDialog open={open} onClose={onCloseHandler} onSubmitHandler={onSubmitHandler}>
            {openingHours.map((e, index) => (
                <OpeningHourInput key={e.id} openingHour={e} onOpenTimeChange={({target}) => {
                    openingHours[index].open_time = target.value;
                }} onCloseTimeChange={({target}) => {
                    openingHours[index].close_time = target.value;
                }} onWeekdaysChange={(weekdays) => {
                    openingHours[index].weekday = weekdays;
                }} onDelete={() => {
                    setOpeningHours(openingHours => openingHours.filter((_, hIndex) => hIndex !== index));
                }}/>
            ))}
            {addedOpeningHours.map((e, index) => (
                <OpeningHourInput key={e.id} openingHour={e} onOpenTimeChange={({target}) => {
                    addedOpeningHours[index].open_time = target.value;
                }} onCloseTimeChange={({target}) => {
                    addedOpeningHours[index].close_time = target.value;
                }} onWeekdaysChange={(weekdays) => {
                    addedOpeningHours[index].weekday = weekdays;
                }} onDelete={() => {
                    setAddedOpeningHours(openingHours => openingHours.filter((_, hIndex) => hIndex !== index));
                }} isLast={(index + 1) === addedOpeningHours.length}/>
            ))}
            <div css={errorMessageStyle}>{error}</div>
            <div css={addButtonStyle}>
                <MaterialButton onClick={onSubmitClickHandler} endIcon={<AddIcon/>}>영업시간 추가하기</MaterialButton>
            </div>

            <LoadingDialog open={isLoading}/>
        </TipxyDialog>
    );
}

export default OpeningHourDialog;