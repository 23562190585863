import {css} from "@emotion/react";
import colors from "./Colors";

const buttonHoverStyle = css`
  background-color: ${colors.primary};
  color: ${colors.font.default};
  
  &:hover {
    cursor: pointer;
    background: ${colors.primary_hover};
  }

  &:active{
    background: ${colors.primary};
  }
`

export default buttonHoverStyle