import {css, IconButton} from "@mui/material";
import AddIcon from "../icons/AddIcon";
import colors from "../styles/Colors";

const addButtonStyle = css`
  width: 30px;
  height: 30px;
  background-color: ${colors.primary};
  
  &:hover {
    background-color: ${colors.primary_hover};
  }
`;

const AddButton = ({onClick, ...props}) => {
    return (
        <IconButton css={addButtonStyle} onClick={onClick} {...props}><AddIcon/></IconButton>
    );
}

export default AddButton;