import {css} from "@emotion/react";

const rootStyle = css`
  padding: 16px;
`;

const titleContainerStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;

const titleStyle = css`
  display: inline-block;
  font-size: 24px;
  font-weight: 700;
`;

const MainLayout = ({title, menu, children}) => {
    return (
        <div css={rootStyle}>
            <div css={titleContainerStyle}>
                <span css={titleStyle}>{title}</span>
                {menu}
            </div>
            {children}
        </div>
    );
}

export default MainLayout;