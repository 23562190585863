import {css} from "@emotion/react";
import colors from "../../styles/Colors";
import {Dialog} from "@mui/material";
import MaterialButton from "../MaterialButton";
import React from "react";

const dialogStyle = css`
  & .MuiPaper-root {
    border-radius: 20px;
    border: ${colors.primary} solid 1px;
  }
`;

const containerStyle = css`
  padding: 30px 20px;
  color: ${colors.font.default};
`;

const submitButtonStyle = css`
  border-radius: 0 0 20px 20px;
`;

const TipxyDialog = ({open, onClose, children, onSubmitHandler}) => (
    <Dialog css={dialogStyle} open={open} onClose={onClose} fullWidth>
        <div css={containerStyle}>
            {children}
        </div>
        <MaterialButton css={submitButtonStyle} onClick={onSubmitHandler}>완료</MaterialButton>
    </Dialog>
);

export default TipxyDialog;