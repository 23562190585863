const colors = {
    primary: '#704DFF',
    primary_hover: '#5B38EE',

    secondary: '#AAFF3E',

    background: {
        default: '#181818',
        light: '#2F2E32',
    },

    opacity: {
        white30: '#FFFFFF4D',
        white20: '#FFFFFF30',
        white10: '#FFFFFF1A',
    },

    font: {
        default: '#F1F1F1',
        dim100: '#CECECE',
        dim200: '#ACACAC',
        dim300: '#888'
    },

    profileBgColor: [
        '#CECECE',
        '#FFBB33',
        '#99CC00',
        '#FF4444',
        '#33B5E5',
    ],
}

export default colors;