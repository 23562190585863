import {useState} from "react";
import colors from "../styles/Colors";
import {css} from "@emotion/react";

const toggleButtonStyle = (checked) => css`
  width: 34px;
  height: 34px;
  background-color: ${checked ? colors.primary : colors.font.dim200};
  border-radius: 100%;
  color: ${checked ? colors.font.default : colors.background.light};
  font-size: 16px;
`;

const WeekdayToggleButton = ({weekday, defaultChecked, onChange}) => {
    const [checked, setChecked] = useState(defaultChecked ?? false);

    const toggleChecked = () => {
        setChecked((checked) => {
            onChange(!checked);
            return !checked;
        });
    }

    return (
        <button css={toggleButtonStyle(checked)} onClick={toggleChecked}>
            {weekday}
        </button>
    );
}

export default WeekdayToggleButton;